/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// VALUES ///
import { page_top_g } from "values/spacing";
import { page_sides_g } from "values/spacing";
/// MUI ///
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// ENDPOINTS ///
import { customer_url } from "navigation/endpoints";
import { learn_url } from "navigation/endpoints";
import { quote_url } from "navigation/endpoints";
import { business_details_url } from "navigation/endpoints";
import { basic_sketch_url } from "navigation/endpoints";
import { market_url } from "navigation/endpoints";

export default function LearningList() {
  const navigate = useNavigate();

  return (
    <div style={{ paddingLeft: page_sides_g, paddingRight: page_sides_g, paddingTop: page_top_g, paddingBottom: page_top_g }}>
      <Stack>
        <Typography variant="h3" sx={{ paddingBottom: "10px" }}>
          Tutorials
        </Typography>
        <Button onClick={() => navigate(`/${learn_url}/${customer_url}`)}>
          <Typography variant="body2" align="left" sx={{ width: "100%" }}>
            <b>#1. Customers</b>
          </Typography>
        </Button>
        <Button onClick={() => navigate(`/${learn_url}/${quote_url}`)}>
          <Typography variant="body2" align="left" sx={{ width: "100%" }}>
            <b>#2. Quotes</b>
          </Typography>
        </Button>
        <Button onClick={() => navigate(`/${learn_url}/${business_details_url}`)}>
          <Typography variant="body2" align="left" sx={{ width: "100%" }}>
            <b>#3. Update Business Details</b>
          </Typography>
        </Button>
        <Button onClick={() => navigate(`/${learn_url}/${basic_sketch_url}`)}>
          <Typography variant="body2" align="left" sx={{ width: "100%" }}>
            <b>#4. Basic Drawing</b>
          </Typography>
        </Button>
        <Button onClick={() => navigate(`/${learn_url}/${market_url}`)}>
          <Typography variant="body2" align="left" sx={{ width: "100%" }}>
            <b>#5. Fabricator Market (For Dealers)</b>
          </Typography>
        </Button>
      </Stack>
    </div>
  )
}

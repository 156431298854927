/// MUI ///
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// VALUES ///
import { page_top_g } from "values/spacing";
import { page_sides_g } from "values/spacing";

export default function LearnBasicSketch() {
  return (
    <Stack sx={{ paddingLeft: page_sides_g, paddingRight: page_sides_g, paddingTop: page_top_g, paddingBottom: page_top_g }}>
      <Typography variant="h3">
        Basic Drawing
      </Typography>
      <Typography variant="body2" sx={{ paddingBottom: "5px" }}>
        Learn how use the drawing tool, change materials, fit to slabs and generate quotes.
      </Typography>
      <video controls object-fit="fill" style={{ width: "100%", height: "100%" }}>
        <source src="/assets/video/basic_sketch.mp4" type="video/mp4"></source>
      </video>
    </Stack >
  )
}

/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// MUI ///
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
/// ENDPOINTS ///
import { updates_url } from "navigation/endpoints";
/// VALUES ///
import { page_sides_g } from "values/spacing";
import { page_top_g } from "values/spacing";

export default function Updates() {
  const navigate = useNavigate();

  return (
    <div style={{ paddingLeft: page_sides_g, paddingRight: page_sides_g, paddingTop: page_top_g, paddingBottom: page_top_g }}>
      <Stack>
        <Typography variant="h6">
          Updates
        </Typography>
        <Stack direction="row" justifyContent="flex-start" sx={{ marginTop: "20px" }}>
          <Typography variant="body2">
            <b>Version</b>
          </Typography>
          <Typography variant="body2" sx={{ marginLeft: "200px" }}>
            <b>Date</b>
          </Typography>
        </Stack>
        <Button onClick={() => navigate(`/${updates_url}/105`)} sx={{ width: "100%", paddingLeft: 0, paddingRight: 0 }}>
          <Stack direction="row" justifyContent="flex-start" sx={{ width: "100%" }}>
            <Typography variant="body2">
              1.0.5
            </Typography>
            <Typography variant="body2" sx={{ marginLeft: "200px" }}>
              June 3, 2024
            </Typography>
          </Stack>
        </Button>
      </Stack>
    </div>
  )
}

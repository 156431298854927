/// REACT ///
import { Fragment } from "react";
/// UPDATE ///
import UpdateOneFiveZero from "../updates/105";

export default function LatestUpdate() {
  return (
    <Fragment>
      <UpdateOneFiveZero />
    </Fragment>
  )
}

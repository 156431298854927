/// HOOKS ///
import { useNavigate } from "react-router-dom";
/// COMPONENTS ///
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
/// STYLES ///
import { toolBarStyle } from "./styles";
/// IMAGES ///
import LogoIcon from "../static/logo_icon.png";
/// ENDPOINTS ///
import { learn_url } from "./endpoints";
import { updates_url } from "./endpoints";

export const light_grey_color: string = "#D4D4D4";

export default function NavBar() {
  const navigate = useNavigate();

  const nav_login = () => {
    window.location.href = "https://app.arrisstone.com";
  }

  return (
    <AppBar position="fixed" sx={{ paddingLeft: 0, marginLeft: 0, borderBottom: `1px solid ${light_grey_color}`, height: "50px" }} elevation={0}>
      <Toolbar sx={toolBarStyle} disableGutters={true} variant="dense">
        <img
          onClick={() => navigate("/")}
          src={LogoIcon}
          width="25"
          height="25"
          style={{ objectFit: "contain" }}
          alt="Arris" />
        <Box sx={{ flexGrow: 1 }}>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "20px" }}>
          <Button onClick={() => navigate(learn_url)}>
            Learn
          </Button>
          <Button onClick={() => navigate(updates_url)}>
            Updates
          </Button>
          <Button onClick={nav_login} variant="contained">
            Login
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

import RoadmapBanner from "../roadmap/banner"
import { page_top_g } from "values/spacing";
import { page_sides_g } from "values/spacing";
/// MUI COMPONENTS ///
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { Stack } from "@mui/material";
/// COLORS ///
import { light_grey_color } from "navigation/navbar";
/// IMAGE ///
import Breadcrumb from "static/1_5_0/breadcrumb.png";
import PrintDiagrams from "static/1_5_0/print_diagrams.png";
import Dashboard from "static/1_5_0/dashboard.png";
import QuotePreview from "static/1_5_0/quote_preview.png";
import Seams from "static/1_5_0/seams.png";
import WorkOrder from "static/1_5_0/work_order.png";
import ShopToggle from "static/1_5_0/shop_toggle.png";
import MarketToggle from "static/1_5_0/market_toggle.png";

export default function UpdateOneFiveZero() {
  return (
    <div style={{ paddingLeft: page_sides_g, paddingRight: page_sides_g, paddingTop: page_top_g, paddingBottom: page_top_g }}>
      <Box sx={{
        border: `1px solid ${light_grey_color}`,
        display: "flex",
        flexDirection: "column",
        paddingBottom: "50px",
        paddingLeft: "100px",
        paddingRight: "100px",
        width: {
          "md": "550px",
          "lg": "800px"
        },
        marginLeft: "auto",
        marginRight: "auto"
      }}>
        <RoadmapBanner />
        <Stack>
          <Typography variant="h6" sx={{ fontFamily: "Manrope" }} textAlign="center">
            <b>Update 1.0.5</b>
          </Typography>
          <Typography variant="body2" color="primary" textAlign="center">
            Now Released
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" sx={{ paddingTop: "20px" }}>
          <Stack spacing={1}>
            <Typography variant="body1">
              <span>&mdash;</span> New look
            </Typography>
            <Typography variant="body1">
              <span>&mdash;</span> Seams through Splash/Miter
            </Typography>
            <Typography variant="body1">
              <span>&mdash;</span> Work Orders for Fabricators
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body1">
              <span>&mdash;</span> Improved Print Diagrams
            </Typography>
            <Typography variant="body1">
              <span>&mdash;</span> Breadcrumb Navigation
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="body1" color="primary" textAlign="left" sx={{ paddingTop: "20px" }}>
          <b>Fabricators</b>
        </Typography>
        <Stack direction="row" justifyContent="space-between" sx={{ paddingTop: "10px" }}>
          <Stack spacing={1}>
            <Typography variant="body1">
              <span>&mdash;</span> Toggle between Shop and Market
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body1">
              <span>&mdash;</span> Separate Material Price List
            </Typography>
          </Stack>
        </Stack>
        <Typography variant="body1" color="primary" textAlign="left" sx={{ paddingTop: "20px" }}>
          <b>Dealers</b>
        </Typography>
        <Stack direction="row" justifyContent="space-between" sx={{ paddingTop: "10px" }}>
          <Stack spacing={1}>
            <Typography variant="body1">
              <span>&mdash;</span> Orders Generate Work Order and PO
            </Typography>
          </Stack>
          <Stack spacing={1}>
          </Stack>
        </Stack>
        <Stack sx={{ marginTop: "50px" }}>
          <Typography variant="h6" sx={{ fontFamily: "Manrope" }} textAlign="center">
            <b>Update 1.0.6</b>
          </Typography>
          <Typography variant="body2" color="primary" textAlign="center">
            Coming Soon
          </Typography>
          <Typography variant="body1" sx={{ marginTop: "20px" }}>
            <span>&mdash;</span> Finished Calendar with new look and functionality
          </Typography>
        </Stack>
        <Stack>
          <Typography variant="h5" sx={{ paddingTop: "40px" }}>
            <u>Features in 1.0.5</u>
          </Typography>
          <Typography variant="h6" sx={{ paddingTop: "20px" }}>
            New UI
          </Typography>
          <Typography variant="body2" sx={{ paddingBottom: "20px" }}>
            Improved Dashboard
          </Typography>
          <img src={Dashboard} alt="Dashboard" width="800" height="447" />
          <Typography variant="body2" sx={{ paddingBottom: "20px", paddingTop: "20px" }}>
            And Improved Information Display
          </Typography>
          <img src={QuotePreview} alt="Quote" width="800" height="320" />
          <Typography variant="h6" sx={{ paddingTop: "20px" }}>
            Breadcrumb Navigation
          </Typography>
          <Typography variant="body2" sx={{ paddingBottom: "20px" }}>
            Breadcrumbs allow fast navigation to previous pages
          </Typography>
          <img src={Breadcrumb} alt="BCImage" width="493" height="77" />
          <Typography variant="h6" sx={{ paddingTop: "20px" }}>
            Improved Print Diagrams
          </Typography>
          <Typography variant="body2" sx={{ paddingBOttom: "20px" }}>
            Diagrams are now larger and all counters into a single area, reducing page count and making the images clearer.
          </Typography>
          <img src={PrintDiagrams} alt="PrintDiagram" width="800" height="825" />
          <Typography variant="h6" sx={{ paddingTop: "20px" }}>
            Seams through Miter and Splash
          </Typography>
          <Typography variant="body2" sx={{ paddingBOttom: "20px" }}>
            Seams will now cut through miter and splash to allow larger pieces to easily fit slabs.
          </Typography>
          <img src={Seams} alt="PrintDiagram" width="800" height="600" />
          <Typography variant="h6" sx={{ paddingTop: "20px" }}>
            Work Orders
          </Typography>
          <Typography variant="body2" sx={{ paddingBOttom: "20px" }}>
            Dealers now create a purchase order for their customer and a work order for fabricators. This makes the difference between types of orders easier to distinguish.
          </Typography>
          <img src={WorkOrder} alt="Work Order" width="800" height="321" />
          <Typography variant="h5" sx={{ paddingTop: "40px" }}>
            <u>New for Fabricators</u>
          </Typography>
          <Typography variant="h6" sx={{ paddingTop: "20px" }}>
            Toggle Shop & Market
          </Typography>
          <Typography variant="body2" sx={{ paddingBottom: "20px" }}>
            A new toggle button separates the shop and market. Shop has all the features to run your own shop and generate your own quotes. Market is where you set your market prices and receive Work Orders from dealers.
          </Typography>
          <Stack direction="row" sx={{ marginTop: "10px" }}>
            <img src={ShopToggle} alt="Shop Toggle" width="205" height="58" />
            <img src={MarketToggle} alt="Market Toggle" width="205" height="58" />
          </Stack>
        </Stack>
      </Box>
    </div>
  )
}
/*
Everyone:
      All new look and feel
      Improved drawings on print
      seams through splash/miter

      show:
      autocomplete fields
      breadcrumbs
      price list and material navigation
      filter materials by type
      where to find shop mileage, location, rate
      the toggle button
      Market Orders now Generated a Work Order and Purchase Order - in each section

      dealers:
      when placing order - the purchase order for the customer will be in orders
      the work order for the fabricator will be under in work orders

      fabricators:
      Shop and Markets Separated
      Find wok orders for jobs received from the market under work orders
      Update location through the store
      Material Prices & Price Lists (fabriation, installation, etc) separated*/


/// REACT-ROUTER ///
import { BrowserRouter } from "react-router-dom";
/// COMPONENTS ///
import NavBar from "./navbar";
import MainRoutes from "./routes";

export default function MainRouter() {
  return (
    <BrowserRouter>
      <NavBar />
      <MainRoutes />
    </BrowserRouter >
  )
}

/// REACT-ROUTER ///
import { useLocation } from "react-router-dom";
import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";
/// FM ///
import { AnimatePresence } from "framer-motion";
/// COMPONENTS ///
import LatestUpdate from "../latest";
import Updates from "updates/list";
import UpdateOneFiveZero from "updates/105";
import LearnBasicSketch from "learn/basic_sketch";
import LearnBusinessDetails from "learn/business_details";
import LearnCustomer from "learn/customer";
import LearnMarket from "learn/market";
import LearnQuote from "learn/quote";
import LearnList from "learn";
import Roadmaps from "roadmap";
/// ENDPOINTS ///
import { updates_url } from "./endpoints";
import { learn_url } from "./endpoints";
import { roadmap_url } from "./endpoints";
/// SUB ROUTES ///
import { basic_sketch_url } from "./endpoints";
import { business_details_url } from "./endpoints";
import { customer_url } from "./endpoints";
import { market_url } from "./endpoints";
import { quote_url } from "./endpoints";

export default function MainRoutes() {
  const location = useLocation();

  return (
    <AnimatePresence>
      <Routes location={location} key={location.key}>
        <Route path={"/"}>
          <Route index element={<LatestUpdate />} />
          <Route path={updates_url}>
            <Route index element={<Updates />} />
            <Route path="latest" element={<LatestUpdate />} />
            <Route path="105" element={<UpdateOneFiveZero />} />
          </Route>
          <Route path={learn_url}>
            <Route index element={<LearnList />} />
            <Route path={basic_sketch_url} element={<LearnBasicSketch />} />
            <Route path={business_details_url} element={<LearnBusinessDetails />} />
            <Route path={customer_url} element={<LearnCustomer />} />
            <Route path={market_url} element={<LearnMarket />} />
            <Route path={quote_url} element={<LearnQuote />} />
          </Route>
          <Route path={roadmap_url} element={<Roadmaps />} />
        </Route>
      </Routes>
    </AnimatePresence >
  )
}


/// MUI ///
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
/// VALUES ///
import { page_top_g } from "values/spacing";
import { page_sides_g } from "values/spacing";

export default function LearnMarket() {
  return (
    <Stack sx={{ paddingLeft: page_sides_g, paddingRight: page_sides_g, paddingTop: page_top_g, paddingBottom: page_top_g }}>
      <Typography variant="h3">
        Fabricator Market
      </Typography>
      <Typography variant="body2" sx={{ paddingBottom: "5px" }}>
        Learn how generate market quotes and choose a fabricator to complete a job.
      </Typography>
      <video controls object-fit="fill" style={{ width: "100%", height: "100%" }}>
        <source src="/assets/video/market.mp4" type="video/mp4"></source>
      </video>
    </Stack >
  )
}

/// FM ///
import { Typography } from "@mui/material";
import { motion } from "framer-motion";
/// IMAGES ///
import LogoIcon from "../static/logo_icon.png";

export default function RoadmapBanner() {
  return (
    <motion.div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", paddingTop: "50px", paddingBottom: "50px" }}>
      <img src={LogoIcon} height="150" width="150" alt="Arris" />
      <Typography variant="h2" color="primary" sx={{ marginLeft: "50px", fontFamily: "Manrope", fontSize: "3.5rem" }}>
        Arris<br />
        Roadmap
      </Typography>
    </motion.div>
  )
}
